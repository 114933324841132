import PropTypes, { object } from 'prop-types';
import React from 'react';
import { DataNotFound } from '../../components/common';

function NextOfKin({ authUser }) {
  return (
    <div>
      <DataNotFound
        message={`Hi ${authUser.surname}, You have No next of Kin Record!`}
      />
    </div>
  );
}

NextOfKin.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default NextOfKin;
