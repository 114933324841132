import { Collapse, Tag } from 'antd';
import { isEmpty, isEqual, map, sortBy } from 'lodash';
import PropTypes, { array } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaUserGraduate } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { calendarActions } from '../../config/actions';
import SingleEnrollment from './SingleEnrollment';

function Enrollments({ enrollmentHistories }) {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(['1']);
  const { calendarYearEvents } = useSelector((state) => state.calendar);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { currentSemester } = useSelector((state) => state.app);

  useEffect(() => {
    if (isEmpty(calendarYearEvents)) {
      dispatch(
        calendarActions.getAcademicYearEvents(currentStudentProgramme.id)
      );
    }
  }, []);

  return (
    <Collapse
      accordion
      defaultActiveKey={['1']}
      activeKey={activeKey}
      onChange={setActiveKey}
      expandIconPosition="end"
      items={map(
        sortBy(enrollmentHistories, 'studyYear.programme_study_years'),
        (enrollment, index) => ({
          key: `${index + 1}`,
          label: (
            <span
              className={`text-uppercase ${
                isEqual(activeKey[0], `${index + 1}`) ? 'text-danger' : ''
              }`}
            >
              <FaUserGraduate className="me-2" />
              {` ${enrollment?.studyYear?.programme_study_years}, ${enrollment.event?.semester?.semester?.metadata_value} - ${enrollment.event.academicYear?.academicYear?.metadata_value}`}
              {isEqual(
                enrollment.event?.semester?.semester?.metadata_value,
                calendarYearEvents[0]?.semester
              ) &&
                isEqual(
                  enrollment?.event?.academicYear?.academicYear?.metadata_value,
                  calendarYearEvents[0]?.academic_year
                ) &&
                isEqual(
                  currentSemester?.semester,
                  enrollment.event?.semester?.semester?.metadata_value
                ) && (
                  <span className="mx-3">
                    <Tag color="green">CURRENT</Tag>
                  </span>
                )}
            </span>
          ),
          children: (
            <SingleEnrollment
              event={
                enrollment.event
                  ? {
                      academic_year:
                        enrollment.event.academicYear?.academicYear
                          ?.metadata_value,
                      semester:
                        enrollment.event?.semester?.semester?.metadata_value,
                    }
                  : {}
              }
              enrollment={enrollment}
            />
          ),
        })
      )}
    />
  );
}

Enrollments.defaultProps = {
  enrollmentHistories: [],
};

Enrollments.propTypes = {
  enrollmentHistories: PropTypes.oneOfType([array]),
};

export default Enrollments;
