import { Card } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataNotFound, ReloadButton } from '../../components/common';
import { enrollmentActions } from '../../config/actions';
import Enrollments from './Enrollments';

function EnrollmentHistory() {
  const dispatch = useDispatch();
  const { enrollmentHistories, gettingEnrollmentHistory } = useSelector(
    (state) => state.enrollment
  );
  const { currentStudentProgramme } = useSelector((state) => state.auth);

  const reloadEnrollmentHistory = () => {
    dispatch(
      enrollmentActions.getEnrollmentHistory(currentStudentProgramme?.id)
    );
  };

  useEffect(() => {
    if (isEmpty(enrollmentHistories)) reloadEnrollmentHistory();
  }, []);

  return (
    <>
      <Card
        loading={gettingEnrollmentHistory && isEmpty(enrollmentHistories)}
        title={
          <span className="text-primary">
            MY ENROLLMENT HISTORY
            {` (${
              !isEmpty(enrollmentHistories) ? enrollmentHistories.length : 0
            })`}
          </span>
        }
        extra={
          <ReloadButton
            loading={gettingEnrollmentHistory}
            onClick={reloadEnrollmentHistory}
          />
        }
        className="border text-secondary text-sm font600"
      >
        <div>
          {isEmpty(enrollmentHistories) ? (
            <div>
              <DataNotFound message="You have No Enrollment History." />
              <div className="text-center">
                <ReloadButton
                  loading={gettingEnrollmentHistory}
                  onClick={reloadEnrollmentHistory}
                />
              </div>
            </div>
          ) : (
            <Enrollments enrollmentHistories={enrollmentHistories} />
          )}
        </div>
      </Card>
    </>
  );
}

export default EnrollmentHistory;
