import { Button, Card, Col, Flex, Row } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useRef, useState } from 'react';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';
import { CustomModal, SubmitButton } from '../../components/common';
import EnrollmentCard from './EnrollmentCard';
import GeneratePaymentReference from './GeneratePaymentReference';

function SingleEnrollment({ event, enrollment }) {
  const componentRef = useRef();
  const [showCardModal, setShowCardModal] = useState(false);

  const printHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Card
        bodyStyle={{ padding: '7px 15px' }}
        className="text-info font600 text-xs"
      >
        <Flex align="center" justify="space-between" wrap>
          <div> ENROLLMENT DETAILS</div>
          {!isEmpty(enrollment) && (
            <div>
              <Button
                icon={<FaPrint />}
                style={{ color: '#3b5999' }}
                className="text-sm fw-bold"
                disabled={false}
                size="small"
                onClick={() => setShowCardModal(true)}
              >
                PRINT PROOF OF ENROLLMENT
              </Button>
            </div>
          )}
        </Flex>
      </Card>

      <Row gutter={[16, 9]} className="my-2">
        <Col xs={24} md={8}>
          <Card
            bodyStyle={{ padding: '10px 15px' }}
            className="bg-light border"
          >
            <p className="text-sm">
              <span className="font600 text-uppercase me-1">
                Academic Year:
              </span>
              {event.academic_year}
            </p>
            <p className="text-sm mb-0">
              <span className="font600 text-uppercase me-1">SEMESTER:</span>
              {event.semester}
              <span className="font600 text-uppercase mx-1">STUDY YEAR:</span>
              {enrollment.studyYear?.programme_study_years}
            </p>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            bodyStyle={{ padding: '10px 15px' }}
            className=" bg-light border"
          >
            <p className="text-sm">
              <span className="font600 text-uppercase me-1">ENROLLED AS:</span>
              {enrollment.enrollmentStatus?.metadata_value}
            </p>
            <p className="text-sm mb-0">
              <span className="font600 text-uppercase me-1">ENROLLED BY:</span>
              {enrollment.enrolled_by === 'STUDENT'
                ? 'SELF'
                : enrollment.enrolled_by}
            </p>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card
            bodyStyle={{ padding: '10px 15px' }}
            className="bg-light border"
          >
            <p className="text-sm">
              <span className="font600 text-uppercase me-1">
                Enrollment Token:
              </span>
              {enrollment.enrollment_token}
            </p>
            <p className="text-sm mb-0">
              <span className="font600 text-uppercase me-1">Enrolled on:</span>
              {moment(enrollment.created_at).format(
                'ddd, MMM Do YYYY, h:mm:ss a'
              )}
            </p>
          </Card>
        </Col>
      </Row>
      <div className="mb-3">
        <GeneratePaymentReference enrollment={enrollment} />
      </div>

      {showCardModal && (
        <CustomModal
          show={showCardModal}
          onCloseModal={() => setShowCardModal(false)}
          title="PROOF OF ENROLLMENT"
          scrollable
          centered
          size="lg"
        >
          <Helmet>
            <title>PROOF OF ENROLLMENT</title>
          </Helmet>
          <ModalBody className="text-sms" ref={componentRef}>
            <EnrollmentCard record={{ event, enrollment }} />
          </ModalBody>
          <ModalFooter className="py-2 bg-light">
            <SubmitButton
              htmlType="button"
              type="primary"
              danger
              text="CANCEL"
              onClick={() => setShowCardModal(false)}
            />
            <SubmitButton
              htmlType="button"
              text="PRINT"
              iconBefore={<FaPrint className="me-2" />}
              onClick={printHandler}
            />
          </ModalFooter>
        </CustomModal>
      )}
    </>
  );
}

SingleEnrollment.defaultProps = {
  enrollment: {},
  event: {},
};

SingleEnrollment.propTypes = {
  enrollment: PropTypes.oneOfType([object]),
  event: PropTypes.oneOfType([object]),
};

export default SingleEnrollment;
