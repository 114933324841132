import { filter, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { BookOutlined, FormOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
} from '../../components/common';
import {
  enrollmentActions,
  registrationActions,
  settingActions,
} from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import EnrollForm from '../EnrollmentHistory/EnrollForm';
import EnrolledStudent from '../EnrollmentHistory/EnrolledStudent';
import Registration from '../RegistrationHistory/Registration';

function CurrentEvents() {
  const dispatch = useDispatch();
  const { currentEvents, gettingCurrentEvents } = useSelector(
    (state) => state.enrollment
  );
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const { activeProgramme } = useSelector((state) => state.auth);
  const [selectedKey, setSelectedKey] = useState('enrollment');
  const [semesterEvent, setSemesterEvent] = useState({});

  const reloadEnrollmentEvent = () => {
    if (!isEmpty(activeProgramme)) {
      dispatch(enrollmentActions.getCurrentEvents(activeProgramme.id));

      if (
        isEmpty(currentEvents.studentRegistration) &&
        !isEmpty(currentEvents.registrationEvent)
      ) {
        dispatch(registrationActions.getCourseUnits(activeProgramme.id));
      }
    } else toast.error('YOU CURRENTLY HAVE NO ACTIVE PROGRAMME');
  };

  const navigateUser = (route = 'enrollment') => {
    if (!isEmpty(MenuList)) {
      const { SelfEnrollment } = MenuList;

      dispatch(
        settingActions.setNavigation({
          ...appNavigation,
          menuLevel_1: 'Enrollment & Registration',
          menuLevel_2: SelfEnrollment?.label,
          menuLevel_3: route,
        })
      );
    }
  };

  useEffect(() => {
    if (isEmpty(semesterEvent)) reloadEnrollmentEvent();
  }, []);

  useEffect(() => {
    if (!isEmpty(currentEvents)) {
      const findEvent = currentEvents.find(
        (event) =>
          parseInt(event.programme_id, 10) === parseInt(activeProgramme.id, 10)
      );
      setSemesterEvent(findEvent ? findEvent.event : {});
    }
  }, [currentEvents, activeProgramme]);

  useEffect(() => {
    const { key } = selectedMenu;
    if (key === 'self-enrollment') navigateUser(selectedKey);
  }, [selectedMenu]);

  return (
    <>
      <Card
        loading={gettingCurrentEvents && isEmpty(semesterEvent)}
        tabList={filter(
          [
            {
              key: 'enrollment',
              tab: 'ENROLLMENT',
              icon: <FormOutlined />,
            },
            {
              key: 'registration',
              tab: 'REGISTRATION',
              icon: <BookOutlined />,
              hidden:
                isEmpty(semesterEvent.studentEnrollment) ||
                isEmpty(semesterEvent.registrationEvent),
            },
          ],
          (item) => !item.hidden
        )}
        tabProps={{
          type: 'card',
          defaultActiveKey: 'enrollment',
          rootClassName: 'font600',
          tabBarStyle: {
            marginTop: '15px',
          },
        }}
        activeTabKey={selectedKey}
        onTabChange={setSelectedKey}
        tabBarExtraContent={
          <ReloadButton
            loading={gettingCurrentEvents}
            onClick={reloadEnrollmentEvent}
          />
        }
        key={semesterEvent?.id}
      >
        {selectedKey === 'enrollment' && (
          <div>
            {gettingCurrentEvents && isEmpty(semesterEvent) ? (
              <DataSpinner />
            ) : (
              <div>
                {isEmpty(semesterEvent.enrollmentEvent) ||
                isEmpty(activeProgramme) ? (
                  <DataNotFound
                    message="There is no Enrollment event available"
                    className="text-xl text-uppercase"
                  />
                ) : (
                  <div>
                    {!isEmpty(semesterEvent.studentEnrollment) ? (
                      <EnrolledStudent
                        studentEnrollment={
                          semesterEvent.studentEnrollment || {}
                        }
                        event={semesterEvent.enrollmentEvent}
                      />
                    ) : (
                      <EnrollForm
                        enrollmentEvent={semesterEvent.enrollmentEvent}
                        activeProgramme={activeProgramme}
                        reloadEnrollmentEvent={reloadEnrollmentEvent}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {selectedKey === 'registration' && (
          <Registration
            registrationEvent={semesterEvent.registrationEvent}
            registrationHistory={semesterEvent.studentRegistration}
            enrollmentEvent={semesterEvent.enrollmentEvent}
            enrollmentHistory={semesterEvent.studentEnrollment}
            reloadEnrollmentEvent={reloadEnrollmentEvent}
          />
        )}
      </Card>
    </>
  );
}

export default CurrentEvents;
