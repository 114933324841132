import { Card, Collapse, Flex, Form, Space } from 'antd';
import { find, isEmpty, map, sum, sumBy, toInteger } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  AlertMessage,
  AntDInputText,
  ReloadButton,
  SubmitButton,
} from '../../components/common';
import { paymentActions, paymentReferenceActions } from '../../config/actions';
import UnPaidInvoices from './UnPaidInvoices';
import { getInvoiceAmount } from './helper';

function GeneratePaymentReference({ navigateUser, activeTab }) {
  const dispatch = useDispatch();
  const [invoiceType, setInvoiceType] = useState('future-payments');
  const [totalInvoiceDue, setTotalInvoiceDue] = useState(0);
  const [amountToDeposit, setAmountToDeposit] = useState(0);
  const [allUnPaidInvoices, setAllUnPaidInvoices] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { invoices, loading } = useSelector((state) => state.myInvoice);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { generatingPaymentReference } = useSelector(
    (state) => state.paymentReference
  );
  const [form] = Form.useForm();

  const getInvoices = () =>
    dispatch(
      paymentActions.getMyInvoices({
        student_programme_id: currentStudentProgramme?.student_programme_id,
      })
    );

  const handleOnChangeForm = ({ amount }) => setAmountToDeposit(amount);

  useEffect(() => {
    if (isEmpty(invoices)) getInvoices();
  }, []);

  useEffect(() => {
    if (amountToDeposit) form.setFieldsValue({ amount: amountToDeposit });
  }, [amountToDeposit]);

  useEffect(() => {
    if (!isEmpty(invoices)) {
      let invoiceAmountsDue = 0;
      let unPaidInvoices = [];

      invoices.forEach((invoice) => {
        const tuitionDue = sumBy(invoice.tuition_invoices, 'amount_due');
        const functionalDue = sumBy(
          invoice.functional_fees_invoices,
          'amount_due'
        );
        const otherFeesDue = sumBy(invoice.other_fees_invoices, 'amount_due');
        const manualsDue = sumBy(invoice.manual_invoices, 'amount_due');
        invoiceAmountsDue += sum([
          tuitionDue,
          functionalDue,
          otherFeesDue,
          manualsDue,
        ]);

        const unPaidTuition = invoice?.tuition_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        const unPaidFunctional = invoice?.functional_fees_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        const unPaidManual = invoice?.manual_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        const unPaidOther = invoice?.other_fees_invoices?.filter(
          (unpaidInvoice) => unpaidInvoice.amount_due > 0
        );
        unPaidInvoices = [
          ...unPaidInvoices,
          ...unPaidTuition,
          ...unPaidFunctional,
          ...unPaidManual,
          ...unPaidOther,
        ];
      });
      if (!isEmpty(unPaidInvoices)) setInvoiceType('all-unpaid-invoices');

      setTotalInvoiceDue(invoiceAmountsDue);
      setAllUnPaidInvoices(unPaidInvoices);
      setSelectedRows(
        unPaidInvoices.map((unpaidInvoice) => ({
          id: unpaidInvoice?.id,
          invoice_number: unpaidInvoice.invoice_number,
          amount: unpaidInvoice.amount_due,
        }))
      );
    }
  }, [invoices]);

  const invoiceTypes = [
    {
      title: 'PAY FOR ALL PENDING INVOICES',
      url: 'all-unpaid-invoices',
      hidden: isEmpty(allUnPaidInvoices),
      children: (
        <>
          <UnPaidInvoices
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            invoices={allUnPaidInvoices}
          />
          <Card
            bodyStyle={{ padding: '5px 10px' }}
            className="fw-bold border-0 text-sm py-1 my-3 bg-dark text-white"
          >
            <Flex align="center" justify="between">
              <span>TOTAL AMOUNT TO PAY</span>
              <div className="card-options text-white">
                {`${totalInvoiceDue.toLocaleString()} UGX`}
              </div>
            </Flex>
          </Card>
        </>
      ),
    },
    {
      title: 'MAKE PARTIAL PAYMENT ON PENDING INVOICES',
      url: 'select-unpaid-invoices',
      hidden: isEmpty(allUnPaidInvoices),
      children: (
        <>
          <UnPaidInvoices
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            invoices={allUnPaidInvoices}
            editAmount
          />

          <Card
            bodyStyle={{ padding: '5px 10px' }}
            className="fw-bold border-0 text-sm py-1 my-3 bg-dark text-white"
          >
            <Flex align="center" justify="between">
              <span>TOTAL AMOUNT TO PAY</span>
              <div className="card-options text-white">
                {`${sumBy(selectedRows, 'amount').toLocaleString()} UGX`}
              </div>
            </Flex>
          </Card>
        </>
      ),
    },
    {
      title: 'DEPOSIT TO MY ACCOUNT',
      url: 'future-payments',
      hidden: false,
      children: (
        <Form form={form} onValuesChange={handleOnChangeForm}>
          <AntDInputText
            name="amount"
            label="AMOUNT TO DEPOSIT"
            rules={[
              {
                required: true,
                message: 'Enter the amount to Deposit E.g 1,000,000',
              },
            ]}
            type="number"
            inputAttributes={{
              min: 1,
              max: 100000000,
            }}
          />

          {amountToDeposit && (
            <div>{toInteger(amountToDeposit).toLocaleString()} UGX</div>
          )}
        </Form>
      ),
    },
  ];

  useEffect(() => {
    const tab = find(invoiceTypes, (type) => type.url === invoiceType);

    const activeInvoiceTab = !isEmpty(allUnPaidInvoices)
      ? tab?.title
      : 'To make full payment';

    if (!isEmpty(activeTab)) navigateUser('Generate New PRN', activeInvoiceTab);
  }, [activeTab]);

  const handleFormSubmit = (selectedInvoice) => {
    let payload = {};
    let url = 'future';
    if (selectedInvoice === 'all-unpaid-invoices') url = 'all';
    else if (selectedInvoice === 'select-unpaid-invoices') {
      url = 'bulk';
      payload = {
        tuition_invoices: getInvoiceAmount(
          selectedRows,
          'T',
          'tuition_invoice_id'
        ),
        functional_fees_invoices: getInvoiceAmount(
          selectedRows,
          'F',
          'functional_fees_invoice_id'
        ),
        other_fees_invoices: getInvoiceAmount(
          selectedRows,
          'O',
          'functional_fees_invoice_id'
        ),
        manual_invoices: getInvoiceAmount(
          selectedRows,
          'M',
          'manual_invoice_id'
        ),
      };
    } else {
      if (toInteger(amountToDeposit) < 1) {
        toast.error('Enter a valid amount to deposit');

        return;
      }
      payload = {
        amount: amountToDeposit,
      };
    }
    dispatch(
      paymentReferenceActions.generatePaymentReference(
        payload,
        url,
        currentStudentProgramme?.id
      )
    );
  };

  return (
    <>
      {isEmpty(allUnPaidInvoices) && (
        <AlertMessage
          message="You Have No Unpaid Invoices"
          type="warning"
          className="mb-2"
          extras={
            <ReloadButton
              loading={loading}
              onClick={getInvoices}
              text="Reload"
            />
          }
        />
      )}

      <Space className="w-100" direction="vertical">
        <Collapse
          className="w-100"
          accordion
          size="small"
          destroyInactivePanel
          activeKey={invoiceType}
          onChange={([type]) => setInvoiceType(type)}
          items={map(invoiceTypes, (type) => ({
            label: (
              <div
                className={`fw-bold ${
                  invoiceType === type.url ? 'text-danger' : 'text-dark'
                }`}
              >{`GENERATE PRN TO ${type.title}`}</div>
            ),
            key: type.url,
            disabled: type.hidden,
            collapsible: type.hidden ? 'disabled' : '',
            children: (
              <>
                {type.children}
                <div className="text-end">
                  <SubmitButton
                    text="GENERATE PRN"
                    onClick={() => handleFormSubmit(type.url)}
                    size="small"
                    loading={
                      generatingPaymentReference && invoiceType === type.url
                    }
                    loadingText="Generating..."
                  />
                </div>
              </>
            ),
          }))}
        />
      </Space>
    </>
  );
}

GeneratePaymentReference.defaultProps = {
  activeTab: null,
};

GeneratePaymentReference.propTypes = {
  activeTab: PropTypes.string,
  navigateUser: PropTypes.func.isRequired,
};

export default GeneratePaymentReference;
