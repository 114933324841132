/* eslint-disable jsx-a11y/control-has-associated-label */
import { PrinterOutlined } from '@ant-design/icons';
import { Card, Image, Space } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, ModalBody, Row, Table } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import avatar from '../../../assets/img/avatar.png';
import {
  AlertMessage,
  DataNotFound,
  ReloadButton,
  SubmitButton,
} from '../../../components/common';
import FormModal from '../../../components/common/FormModal';
import { paymentActions, settingActions } from '../../../config/actions';
import MenuList from '../../../config/routes/MenuList';
import { formatName, whichInstitution } from '../../../helpers/dataFormatter';
// import LedgerTable from './LedgerTable';
// import MigratedLedgerTable from './MigratedLedgerTable';

function StudentPaymentLedger() {
  const dispatch = useDispatch();
  const [myStatement, setMyStatement] = useState([]);
  const [studentData, setStudentData] = useState({});
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const { myStudentLedger, gettingLedger, downloadFinancialStatement } =
    useSelector((state) => state.myTransaction);
  const { accountBalance, currentStudentProgramme } = useSelector(
    (state) => state.auth
  );
  const { invoiceTotalDue } = useSelector((state) => state.myInvoice);
  const { institutionStructure } = useSelector((state) => state.app);
  const isMubs = whichInstitution('MUBS', institutionStructure);

  const getStudentLedger = () =>
    dispatch(paymentActions.getStudentLedger(currentStudentProgramme?.id));

  const downloadLedger = () => {
    dispatch(
      paymentActions.downloadFinancialStatement(currentStudentProgramme?.id)
    );
  };

  useEffect(() => {
    if (isEmpty(myStudentLedger)) getStudentLedger();
    if (!isEmpty(myStudentLedger)) {
      setMyStatement(myStudentLedger.result);
      setStudentData(myStudentLedger.findStudentDetails);
    }
  }, [myStudentLedger]);

  useEffect(() => {
    if (invoiceTotalDue > 0 && accountBalance > 0) {
      setShowDisclaimer(true);
    }
  }, [invoiceTotalDue, accountBalance]);

  const handleActions = () =>
    dispatch(settingActions.setSelectedMenu(MenuList.MyInvoice));

  return (
    <Card
      loading={gettingLedger && isEmpty(myStatement)}
      title={<span className="font600 text-primary">MY LEDGER</span>}
      extra={
        <Space size="small">
          <ReloadButton loading={gettingLedger} onClick={getStudentLedger} />
          {isMubs && (
            <SubmitButton
              loading={downloadFinancialStatement}
              loadingText="DOWNLOADING..."
              size="small"
              icon={<PrinterOutlined />}
              text="DOWNLOAD"
              onClick={downloadLedger}
            />
          )}
        </Space>
      }
    >
      <div>
        {!showDisclaimer && (
          <>
            {isEmpty(myStatement) ? (
              <DataNotFound />
            ) : (
              <>
                <Row>
                  <Col md={9}>
                    <Table size="md" borderless>
                      <tbody>
                        <tr>
                          <td width={50}>
                            <span className="text-xs">TO:</span>
                          </td>
                          <td className="fw-bold">
                            <span className="text-xs">{`${formatName(
                              studentData
                            )} 
                          (${
                            isMubs &&
                            !isEmpty(currentStudentProgramme?.student_number)
                              ? currentStudentProgramme?.old_student_number
                              : currentStudentProgramme?.student_number
                          }) - 
                          ${studentData?.registration_number}`}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="text-xs">AS OF:</span>
                          </td>
                          <td className="fw-bold">
                            <span className="text-xs">
                              {moment().format('llll')}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="text-xs">
                      <span className="fw-bold text-uppercase text-xs p-1 d-block">
                        {studentData?.college_title}
                      </span>
                      <span className="fw-bold text-uppercase text-xs p-1 d-block">
                        {studentData?.faculty_title}
                      </span>
                      <span className="text-uppercase text-xs p-1 d-block">
                        {studentData?.programme_title}
                      </span>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="rounded-sm border-0 text-end p-2 m-2">
                      <Image
                        src={`${process.env.REACT_APP_STUDENTS_PHOTOS_URL}/${studentData?.avatar}`}
                        fallback={avatar}
                        className="mx-auto border bg-white"
                        height="120px"
                        width="120px"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <p className="d-flex justify-content-between">
                    <span>Statement</span>
                    {myStatement?.contextOpeningBalance && (
                      <span>{myStatement?.contextOpeningBalance}</span>
                    )}
                  </p>
                  {isEmpty(myStatement.statement) ? (
                    <Card>
                      <DataNotFound message="No Financial Records Found" />
                    </Card>
                  ) : (
                    <Table size="lg" bordered responsive>
                      <thead>
                        <tr className="fw-bold p-3">
                          <th>~</th>
                          <th width={180}>Time stamp</th>
                          <th>Entry</th>
                          <th width={100}>Narration</th>
                          <th width={100}>Debit</th>
                          <th width={100}>Credit</th>
                          <th width={100}>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myStatement.statement.map((item) => (
                          <tr key={item.sn}>
                            <td>{item.sn}</td>
                            <td>{item.time_stamp}</td>
                            <td>{item.entry}</td>
                            <td>{item.narration}</td>
                            <td>{item.invoice_amount.toLocaleString()}</td>
                            <td>{item.amount_paid.toLocaleString()}</td>
                            <td>{item.current_bal.toLocaleString()}</td>
                          </tr>
                        ))}
                        <tr className="border-bottom-5">
                          <td colSpan={3} className="border-0" />
                          <td colSpan={4} className="border-0">
                            <div className="d-flex justify-content-between fw-bold py-2">
                              <span>UGX NET STATEMENT BALANCE</span>
                              <span className="text-end">
                                {myStatement.statement[
                                  myStatement.statement.length - 1
                                ].current_bal.toLocaleString()}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </Row>
              </>
            )}
          </>
        )}
      </div>
      {showDisclaimer && (
        <FormModal
          formTitle="INFORMATION"
          defaultShow={showDisclaimer}
          backdrop="static"
          onCloseModal={handleActions}
          handleSubmit={handleActions}
          submitButtonProps={{
            text: 'Allocate',
          }}
        >
          <ModalBody>
            <AlertMessage
              variant="info"
              message="You cannot access your ledger because you
               have pending invoices and unallocated funds"
            />
            <div className="d-flex">
              <span className="fs-5 text-primary">
                <FaInfoCircle className="me-2 fs-4" />
                Kindly allocate funds to pending invoices
              </span>
            </div>
          </ModalBody>
        </FormModal>
      )}
    </Card>
  );
}

export default StudentPaymentLedger;
