import { Alert } from 'antd';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import SingleEnrollment from './SingleEnrollment';

function EnrolledStudent({ studentEnrollment, event }) {
  return (
    <>
      <Alert
        type="success"
        showIcon
        className="font600 text-uppercase text-sm mb-3"
        message={
          <>
            Enrolled for
            <span className="mx-1">
              {`${studentEnrollment?.studyYear?.programme_study_years},`}
            </span>
            {`${event.semester} - ${event.academic_year}`}
          </>
        }
      />
      <SingleEnrollment event={event} enrollment={studentEnrollment} />
    </>
  );
}

EnrolledStudent.defaultProps = {
  studentEnrollment: {},
  event: {},
};

EnrolledStudent.propTypes = {
  studentEnrollment: PropTypes.oneOfType([object]),
  event: PropTypes.oneOfType([object]),
};

export default EnrolledStudent;
