import { includes, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import { AccountBookOutlined, PayCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadButton } from '../../../components/common';
import { paymentActions } from '../../../config/actions';
import Transactions from './Transactions';

function MyTransactions() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { transactions, loading, myStudentLedger } = useSelector(
    (state) => state.myTransaction
  );
  const [currentMenu, setCurrentMenu] = useState('data');

  const transactionActions = ['data', 'deposits'];
  const isTransaction = includes(transactionActions, currentMenu);

  useEffect(() => {
    if (isEmpty(myStudentLedger))
      dispatch(paymentActions.getStudentLedger(currentStudentProgramme?.id));
  }, []);

  const getTransactions = () => dispatch(paymentActions.getMyTransactions());

  useEffect(() => {
    if (isEmpty(transactions)) getTransactions();
  }, []);

  return (
    <Card
      loading={loading}
      activeTabKey={currentMenu}
      onTabChange={setCurrentMenu}
      tabList={[
        {
          key: 'data',
          tab: 'INVOICE PAYMENTS',
          icon: <PayCircleOutlined />,
        },
        {
          key: 'deposits',
          tab: 'FEES DEPOSITS',
          icon: <AccountBookOutlined />,
        },
      ]}
      tabProps={{
        type: 'card',
        defaultActiveKey: 'data',
        rootClassName: 'font600',
        tabBarStyle: {
          marginTop: '15px',
        },
      }}
      tabBarExtraContent={
        <ReloadButton loading={loading} onClick={() => getTransactions()} />
      }
    >
      <div>{isTransaction && <Transactions currentMenu={currentMenu} />}</div>
    </Card>
  );
}

export default MyTransactions;
