import { PayCircleOutlined, SwitcherOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { filter, isArray, isEmpty, isEqual, pick, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadButton } from '../../../components/common';
import {
  paymentReferenceActions,
  serviceActions,
} from '../../../config/actions';
import PaymentReferencesDetails from '../../PaymentReferences/PaymentReferencesDetails';
import ChangeOfProgrammeHistory from './ChangeOfProgrammeHistory';

function ServiceHistory() {
  const dispatch = useDispatch();
  const [currentMenu, setCurrentMenu] = useState('change-of-programme');
  const [activeKey, setActiveKey] = useState(1);
  const [paymentReferenceData, setPaymentReferenceData] = useState([]);
  const { paymentReferences } = useSelector((state) => state.paymentReference);
  const { gettingServiceHistory, serviceHistories } = useSelector(
    (state) => state.service
  );
  const [changeOfProgrammes, setChangeOfProgrammes] = useState([]);

  const reloadPaymentReferences = () => {
    dispatch(paymentReferenceActions.getPaymentReferences());
  };

  useEffect(() => {
    if (isArray(serviceHistories) && !isEmpty(serviceHistories)) {
      const paymentReference = [];
      const getCOP = serviceHistories.find(
        (service) => toLower(service?.category) === 'change-of-programme'
      );
      const data = getCOP?.data;

      if (!isEmpty(data) && !isEqual(data, changeOfProgrammes)) {
        paymentReference.push({
          ...pick(data[0], [
            'id',
            'ura_prn',
            'amount',
            'expiry_date',
            'generated_by',
          ]),
        });
      }
      setChangeOfProgrammes(data || []);
      setPaymentReferenceData(paymentReference);
    } else setChangeOfProgrammes([]);
  }, []);

  useEffect(() => {
    if (isEmpty(paymentReferences)) reloadPaymentReferences();
  }, []);

  const onClickReload = () => {
    dispatch(serviceActions.getServiceHistory(currentMenu));
  };

  return (
    <Card
      tabList={filter(
        [
          {
            label: 'CHANGE OF PROGRAMME',
            key: 'change-of-programme',
            icon: <SwitcherOutlined />,
          },
          {
            label: 'PAYMENT REFERENCE',
            key: 'payment-reference',
            icon: <PayCircleOutlined />,
            hidden: isEmpty(paymentReferenceData),
          },
        ],
        (item) => !item.hidden
      )}
      tabProps={{
        type: 'card',
        defaultActiveKey: 'change-of-programme',
        rootClassName: 'font600',
        tabBarStyle: {
          marginTop: 15,
        },
      }}
      activeTabKey={currentMenu}
      onTabChange={setCurrentMenu}
      tabBarExtraContent={
        <>
          {currentMenu === 'change-of-programme' && (
            <ReloadButton
              onClick={onClickReload}
              loading={gettingServiceHistory}
            />
          )}
        </>
      }
    >
      <div>
        {currentMenu === 'change-of-programme' && (
          <ChangeOfProgrammeHistory changeOfProgrammes={changeOfProgrammes} />
        )}
        {currentMenu === 'payment-reference' && (
          <PaymentReferencesDetails
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            paymentData={paymentReferenceData}
            referenceTab="inactive"
            usage="COP"
          />
        )}
      </div>
    </Card>
  );
}

export default ServiceHistory;
