import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';

function GuardianDetail({ authUser }) {
  return (
    <>
      <Card.Body>
        <Table
          striped
          size="md"
          borderless
          responsive
          cellPadding={4}
          className="border"
        >
          <tbody>
            <tr>
              <td width={200} className="text-uppercase font600">
                FULL NAME
              </td>
              <td className="border-start">:{authUser.guardian_name}</td>
              <td width={200} className="text-uppercase font600 border-start">
                Relationship
              </td>
              <td>{authUser.guardian_relationship}</td>
            </tr>
            <tr>
              <td className="text-uppercase font600">Email</td>
              <td>:{authUser.guardian_email}</td>
              <td className="text-uppercase font600 border-start">Address</td>
              <td>:{authUser.guardian_address}</td>
            </tr>
            <tr>
              <td className="text-uppercase">TELEPHONE PHONE</td>
              <td colSpan={3}>:{authUser.guardian_phone}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
    </>
  );
}

GuardianDetail.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default GuardianDetail;
