import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { ConfigProvider, theme } from 'antd';
import App from './App';
import rootSaga from './config/saga';
import history from './config/services/historyService';
import httpService from './config/services/httpService';
import configureStore from './config/store';
import reportWebVitals from './reportWebVitals';
// import registerServiceWorker from './registerServiceWorker';

const store = configureStore();
store.runSaga(rootSaga);
httpService.setupInterceptors(store, history);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <CookiesProvider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: process.env.REACT_APP_PRIMARY_COLOR || '#10ac84',
                colorWarning: process.env.REACT_APP_WARNING_COLOR || '#cd201f',
                algorithm: theme.darkAlgorithm,
              },
            }}
            componentSize="default"
          >
            <ToastContainer
              position="top-center"
              autoClose={2000}
              className="p-2 font600"
              limit={1}
            />
            <App />
          </ConfigProvider>
        </CookiesProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
