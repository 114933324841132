import { BookOutlined } from '@ant-design/icons';
import { Card, Collapse, Tag } from 'antd';
import { filter, isEmpty, isEqual, map, toString } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataNotFound, ReloadButton } from '../../components/common';
import { calendarActions, registrationActions } from '../../config/actions';
import RegistrationCard from './RegistrationCard';
import SingleRegistrationHistory from './SingleRegistrationHistory';

function RegistrationHistory() {
  const dispatch = useDispatch();
  const { registrationHistories, gettingRegistrationHistory } = useSelector(
    (state) => state.registration
  );
  const { calendarYearEvents } = useSelector((state) => state.calendar);
  const { showModal } = useSelector((state) => state.setting);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { currentSemester } = useSelector((state) => state.app);

  const [activeKey, setActiveKey] = useState(['1']);
  const [selectedHistory, setSelectedHistory] = useState({});
  const [registrationData, setRegistrationData] = useState([]);

  const reloadRegistrationHistory = () => {
    dispatch(
      registrationActions.getRegistrationHistory(currentStudentProgramme?.id)
    );
  };

  useEffect(() => {
    if (isEmpty(registrationHistories)) reloadRegistrationHistory();
  }, []);

  useEffect(() => {
    if (isEmpty(calendarYearEvents)) {
      dispatch(
        calendarActions.getAcademicYearEvents(currentStudentProgramme.id)
      );
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(registrationHistories)) {
      const filteredRegistrations = filter(
        registrationHistories,
        (reg) => reg.is_active
      );

      setRegistrationData(filteredRegistrations || []);
    }
  }, [registrationHistories]);

  return (
    <>
      <Card
        loading={gettingRegistrationHistory && isEmpty(registrationHistories)}
        title={
          <span className="py-2 px-3 text-primary text-sm mb-2 font600">
            MY REGISTRATION HISTORY
          </span>
        }
        extra={
          <ReloadButton
            loading={gettingRegistrationHistory}
            onClick={reloadRegistrationHistory}
          />
        }
      >
        {isEmpty(registrationHistories) ? (
          <>
            <DataNotFound message="You have No Registration History." />
            <div className="text-center">
              <ReloadButton
                loading={gettingRegistrationHistory}
                onClick={reloadRegistrationHistory}
              />
            </div>
          </>
        ) : (
          <>
            <Collapse
              activeKey={activeKey}
              onChange={setActiveKey}
              expandIconPosition="end"
              accordion
              defaultActiveKey={['1']}
              items={map(registrationData, (registrationHistory, index) => ({
                key: toString(index + 1),
                label: (
                  <span
                    className={`text-teal text-uppercase ${
                      isEqual(activeKey[0], `${index + 1}`) ? 'text-danger' : ''
                    } fw-bold`}
                  >
                    <BookOutlined className="me-2" />
                    {`REGISTRATION FOR ${registrationHistory?.registration_study_year}, ${registrationHistory.event_semester} - ${registrationHistory.academic_year}`}
                    {isEqual(
                      registrationHistory?.academic_year,
                      calendarYearEvents[0]?.academic_year
                    ) &&
                      isEqual(
                        registrationHistory?.event_semester,
                        calendarYearEvents[0]?.semester
                      ) &&
                      isEqual(
                        currentSemester?.semester,
                        registrationHistory?.event_semester
                      ) && (
                        <span className="mx-3">
                          <Tag color="green">CURRENT</Tag>
                        </span>
                      )}
                  </span>
                ),
                children: (
                  <SingleRegistrationHistory
                    registrationHistory={registrationHistory}
                    setSelectedHistory={setSelectedHistory}
                  />
                ),
              }))}
            />
          </>
        )}
      </Card>

      {showModal && <RegistrationCard selectedRegistration={selectedHistory} />}
    </>
  );
}

export default RegistrationHistory;
