import { find, isEmpty, isFunction } from 'lodash';
import PropTypes, { any } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Card, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  DataNotFound,
  DataSpinner,
  ReloadButton,
} from '../../components/common';
import {
  appActions,
  authActions,
  registrationActions,
  serviceActions,
} from '../../config/actions';
import { formatMetadata } from '../../helpers/dataFormatter';
import usePrevious from '../Hooks/usePrevious';
import RegistrationForm from './RegistrationForm';

function RegisterStudent({
  registrationEvent,
  enrollmentEvent,
  enrollmentHistory,
  reloadRegistrationEvent,
  isAdministrative,
}) {
  const dispatch = useDispatch();
  const { authUser, activeProgramme } = useSelector((state) => state.auth);
  const { metadata } = useSelector((state) => state.app);
  const {
    courseUnitsByStudyYear,
    gettingCourseUnits,
    registeringStudent,
    gettingCurrentEvents,
    registrationError,
    registrationSuccess,
  } = useSelector((state) => state.registration);
  const [registrationStatusOptions, setRegistrationStatusOptions] = useState(
    []
  );
  const [provisionalRegistrationOptions, setProvisionalRegistrationOptions] =
    useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [registrationType, setRegistrationType] = useState(null);
  const [selectedCourseUnits, setSelectedCourseUnits] = useState([]);
  const prevState = usePrevious({ registrationError, registrationSuccess });

  const getCourseUnits = () => {
    if (!isEmpty(activeProgramme)) {
      dispatch(registrationActions.getCourseUnits(activeProgramme.id));
    }
  };

  useEffect(() => {
    if (isEmpty(metadata)) dispatch(appActions.getMetadata());
    if (isEmpty(authUser)) dispatch(authActions.getAuthUser());
    if (isEmpty(courseUnitsByStudyYear)) getCourseUnits();
  }, []);

  useEffect(() => {
    if (!isEmpty(metadata)) {
      setRegistrationStatusOptions(
        formatMetadata(metadata, 'REGISTRATION STATUSES')
      );

      setProvisionalRegistrationOptions(
        formatMetadata(metadata, 'PROVISIONAL REGISTRATION TYPES')
      );
      const registrationTypes = formatMetadata(metadata, 'REGISTRATION TYPES');
      const findFullRegistration = registrationTypes.find((type) =>
        type.label.includes('FULL')
      );
      const findProvRegistration = registrationTypes.find((type) =>
        type.label.includes('PROVISIONAL')
      );
      setRegistrationType(
        isAdministrative ? findProvRegistration : findFullRegistration
      );
    }
  }, [metadata]);

  useEffect(() => {
    setSuccessMessage(null);
    setErrorMessage(null);
    if (!isEmpty(prevState)) {
      if (
        !isEmpty(registrationError) &&
        prevState.registrationError !== registrationError
      ) {
        setErrorMessage(
          registrationError?.error || registrationError.server.message
        );
      }

      if (
        !isEmpty(registrationSuccess) &&
        prevState.registrationSuccess !== registrationSuccess
      ) {
        setSuccessMessage(registrationSuccess?.server?.message);
      }
    }
  }, [registrationError, registrationSuccess]);

  const onClickRegisterButton = (data) => {
    if (isAdministrative && !isEmpty(data)) {
      const dataToSend = {
        event_id: registrationEvent?.id,
        enrollment_id: enrollmentHistory?.id,
        registration_type_id: registrationType ? registrationType.value : null,
        course_units: selectedCourseUnits.map((courseUnit) => ({
          course_unit_id: courseUnit.course_unit_id,
          course_unit_status_id: courseUnit.registrationStatus?.value,
        })),
        provisional_registration_comment:
          find(
            provisionalRegistrationOptions,
            (item) => item.value === data.provisional_registration_type_id
          )?.label || 'CHALLENGES',
        ...data,
      };
      dispatch(serviceActions.submitRegistrationApplication(dataToSend));
    } else if (!isEmpty(registrationEvent)) {
      const dataToSend = {
        event_id: registrationEvent?.id,
        enrollment_id: enrollmentHistory?.id,
        registration_type_id: registrationType ? registrationType.value : null,
        course_units: selectedCourseUnits.map((courseUnit) => ({
          course_unit_id: courseUnit.course_unit_id,
          course_unit_status_id: courseUnit.registrationStatus?.value,
        })),
      };
      dispatch(
        registrationActions.registerStudent(dataToSend, activeProgramme.id)
      );
    }
  };

  const onClickReload = () => {
    getCourseUnits();
    if (isFunction(reloadRegistrationEvent)) reloadRegistrationEvent();
  };

  return (
    <div>
      {isEmpty(registrationEvent) ? (
        <>
          <DataNotFound message="There is currently No open Registration Event." />
          <div className="text-center">
            <ReloadButton
              loading={gettingCurrentEvents}
              onClick={onClickReload}
            />
          </div>
        </>
      ) : (
        <div className="border-0 p-0">
          {errorMessage && <AlertMessage message={errorMessage} />}
          {successMessage && (
            <AlertMessage type="success" message={successMessage} />
          )}

          {gettingCourseUnits && isEmpty(courseUnitsByStudyYear) ? (
            <DataSpinner />
          ) : (
            <>
              <Card
                styles={{ body: { padding: '10px 15px' } }}
                className="font600 text-danger text-xs mb-3 border"
              >
                <Flex align="center" justify="between">
                  <span>
                    REGISTER COURSES FOR
                    <strong className="mx-1 font600">
                      {enrollmentHistory?.studyYear?.programme_study_years}
                    </strong>
                    <strong className="me-1 font600">
                      {`${enrollmentEvent?.semester} -`}
                    </strong>
                    {enrollmentEvent?.academic_year}
                  </span>
                  {activeProgramme && (
                    <div className="card-options">
                      <div className="text-info">
                        PROG:
                        {` ${activeProgramme?.programme_title}`}
                      </div>
                    </div>
                  )}
                </Flex>
              </Card>
              <RegistrationForm
                registrationStatusOptions={registrationStatusOptions}
                provisionalRegistrationOptions={provisionalRegistrationOptions}
                courseUnitsByStudyYear={courseUnitsByStudyYear}
                selectedCourseUnits={selectedCourseUnits}
                setSelectedCourseUnits={setSelectedCourseUnits}
                onClickRegisterButton={onClickRegisterButton}
                registeringStudent={registeringStudent}
                isAdministrative={isAdministrative}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

RegisterStudent.defaultProps = {
  isAdministrative: false,
};

RegisterStudent.propTypes = {
  isAdministrative: PropTypes.bool,
  reloadRegistrationEvent: PropTypes.func.isRequired,
  registrationEvent: PropTypes.oneOfType([any]).isRequired,
  enrollmentHistory: PropTypes.oneOfType([any]).isRequired,
  enrollmentEvent: PropTypes.oneOfType([any]).isRequired,
};

export default RegisterStudent;
