import { isEmpty } from 'lodash';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DataNotFound } from '../../components/common';
import { whichInstitution } from '../../helpers/dataFormatter';

function AcademicDetail({ authUser }) {
  const { institutionStructure } = useSelector((state) => state.app);
  const isMubs = whichInstitution('MUBS', institutionStructure);

  return (
    <>
      {!isEmpty(authUser?.academic_records) ? (
        <>
          {authUser.academic_records.map((academicRecord) => (
            <Card key={academicRecord.id} className="mb-4 border-0">
              <Card.Header className="py-2 bg-light border text-xs m-0 mb-2 text-sm font500">
                {`${academicRecord.programme_code} - ${academicRecord.programme_title}`}
                {academicRecord.is_current_programme === true && (
                  <span className="bg-success ms-1 text-xs py-1 px-2 text-white text-uppercase">
                    Current Programme
                  </span>
                )}
              </Card.Header>
              <Table
                striped
                borderless
                responsive
                cellPadding={4}
                className="border"
              >
                <tbody>
                  <tr>
                    <td width={200} className="text-uppercase">
                      Student Number
                    </td>
                    <td>
                      :
                      {isMubs && !isEmpty(academicRecord?.old_student_number)
                        ? academicRecord?.old_student_number
                        : academicRecord?.student_number}
                    </td>
                    <td width={200} className="text-uppercase">
                      Academic Status
                    </td>
                    <td>:{academicRecord.student_academic_status}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Reg. Number</td>
                    <td>:{academicRecord.registration_number}</td>
                    <td className="text-uppercase">Billing category</td>
                    <td>:{academicRecord.billing_category}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Campus</td>
                    <td>:{academicRecord.campus}</td>
                    <td className="text-uppercase">Marital Status</td>
                    <td>:{academicRecord.marital_status}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Programme Version</td>
                    <td>:{academicRecord.version_title}</td>
                    <td className="text-uppercase">Residence Status</td>
                    <td>:{academicRecord.residence_status}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Intake</td>
                    <td>:{academicRecord.intake}</td>
                    <td className="text-uppercase">Hall of Attachment</td>
                    <td>:{academicRecord.hall_of_attachment}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Entry Academic Year</td>
                    <td>:{academicRecord.entry_academic_year}</td>
                    <td className="text-uppercase">Hall of Residence</td>
                    <td>:{academicRecord.hall_of_residence || 'N/A'}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Entry Study Year</td>
                    <td>:{academicRecord.entry_study_year}</td>
                    <td className="text-uppercase">Fees Waiver</td>
                    <td>:{academicRecord.fees_waiver}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Current Study Year</td>
                    <td>:{academicRecord.current_study_year}</td>
                    <td className="text-uppercase">Has Completed</td>
                    <td>:{academicRecord.has_completed ? 'YES' : 'NO'}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Study Type</td>
                    <td>:{academicRecord.programme_type}</td>
                    <td className="text-uppercase">ON LOAN SCHEME</td>
                    <td>:{academicRecord.is_on_loan_scheme ? 'YES' : 'NO'}</td>
                  </tr>
                  <tr>
                    <td className="text-uppercase">Sponsorship</td>
                    <td>:{academicRecord.sponsorship}</td>
                    <td className="text-uppercase">Affiliated</td>
                    <td>:{academicRecord.is_affiliated ? 'YES' : 'NO'}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          ))}
        </>
      ) : (
        <DataNotFound message="You have No Academic Records!" />
      )}
    </>
  );
}

AcademicDetail.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default AcademicDetail;
