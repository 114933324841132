import { Card, Col, Image, Row } from 'antd';
import { ceil, chunk } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import defaultLogo from '../../assets/img/default.png';

function DefaultHeader({ header }) {
  const { institutionStructure } = useSelector((state) => state.app);

  const formatInstitutionName = (name) => {
    let split = [];

    if (name) split = name?.split(' ');

    return [
      chunk(split, ceil(split.length / 2))[0]?.join(' '),
      chunk(split, ceil(split.length / 2))[1]?.join(' '),
    ];
  };

  return (
    <div>
      <Card body className="border-0 mb-4">
        <Row className="row-deck text-sm mb-4">
          <Col md={10} sm={10} className="justify-content-end">
            <Card className="border-0 px-3 my-auto self-align-end">
              <div className="fs-1 fw-bold text-uppercase">
                {
                  formatInstitutionName(
                    institutionStructure.institution_name
                  )[0]
                }
              </div>
            </Card>
          </Col>
          <Col md={4} sm={4} className="d-flex justify-content-center">
            <Image
              fallback={defaultLogo}
              width="100px"
              height="100px"
              src={`${process.env.REACT_APP_INSTITUTION_LOGO_PATH}/${institutionStructure?.institution_logo}`}
              alt="Logo"
            />
          </Col>
          <Col md={10} sm={10} className="justify-content-start">
            <Card className="border-0 px-3 my-auto text-start">
              <div className="fs-1 fw-bold text-uppercase">
                {
                  formatInstitutionName(
                    institutionStructure.institution_name
                  )[1]
                }
              </div>
            </Card>
          </Col>
        </Row>
        <div className="text-center text-md">
          <Row align="middle" justify="center" className="d-flex w-100 me-3">
            <Col>
              <i className="d-block fs-4 fw-bold">{`Office of the Academic Registrar `}</i>
              <div className="my-2">{`${institutionStructure?.institution_address}`}</div>
              <div className="my-2">{`Tel: ${
                institutionStructure?.telephone_1
              }${
                institutionStructure?.telephone_2
                  ? `/ ${institutionStructure?.telephone_2}`
                  : ''
              }`}</div>
              <div>
                Email: {institutionStructure?.institution_email || ''}, Website:{' '}
                {institutionStructure?.institution_website || ''}
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      <div className="h4 fw-bold pb-2 border-bottom text-center">
        {header}
        <div className="text-sm mt-2 text-muted">
          {`Print Date: ${moment(new Date()).format('llll')}`}
        </div>
      </div>
    </div>
  );
}

DefaultHeader.defaultProps = {
  header: 'DOCUMENT TITLE',
};

DefaultHeader.propTypes = {
  header: PropTypes.string,
};

export default DefaultHeader;
