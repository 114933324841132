import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes, { object } from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../config/actions';
import ChangeContactModal from './ChangeContactModal';

function BioData({ authUser }) {
  const dispatch = useDispatch();
  const { showModal } = useSelector((state) => state.setting);

  return (
    <>
      <Card.Header className="text-primary font600 py-2 text-sm bioData-header">
        <div className="card-options">
          <Button
            type="dashed"
            danger
            size="small"
            icon={<EditOutlined />}
            onClick={() => dispatch(settingActions.setShowModal(true))}
            disabled
          >
            Edit Contacts
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <Table
          striped
          borderless
          responsive
          cellPadding={4}
          className="border rounded"
        >
          <tbody>
            <tr>
              <td width={200} className="text-uppercase font600">
                Surname
              </td>
              <td>:{authUser.surname}</td>
              <td width={200} className="text-uppercase font600">
                Religion
              </td>
              <td>:{authUser.religion}</td>
            </tr>
            <tr>
              <td className="text-uppercase font600">Other Names</td>
              <td>:{authUser.other_names}</td>
              <td className="text-uppercase font600">District</td>
              <td>:{authUser.home_district}</td>
            </tr>
            <tr>
              <td className="text-uppercase font600">Email</td>
              <td>:{authUser.email}</td>
              <td className="text-uppercase font600">Nationality</td>
              <td>:{authUser.nationality}</td>
            </tr>
            <tr>
              <td className="text-uppercase font600">Tel. Phone</td>
              <td>:{authUser.phone}</td>
              <td className="text-uppercase font600">National ID No.</td>
              <td>:{authUser.national_id_number}</td>
            </tr>
            <tr>
              <td className="text-uppercase font600">Sex</td>
              <td>:{authUser.gender}</td>
              <td className="text-uppercase font600">Passport</td>
              <td>:{authUser.passport_id_number}</td>
            </tr>
            <tr>
              <td className="text-uppercase font600">Date of Birth</td>
              <td colSpan={3}>:{authUser.date_of_birth}</td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      {showModal && <ChangeContactModal bioData={authUser} />}
    </>
  );
}

BioData.propTypes = {
  authUser: PropTypes.oneOfType([object]).isRequired,
};

export default BioData;
