const payment = {
  myInvoice: {
    loading: false,
    invoices: [],
    loadError: {},

    gettingGraduationInvoices: false,
    graduationInvoices: [],
    graduationInvoiceError: {},

    invoiceTotalDue: 0,
    shouldAllocate: false,
  },
  myTransaction: {
    loading: false,
    transactions: [],
    loadError: {},

    gettingPreviousPayments: false,
    previousPayments: { transactions: [] },
    previousPaymentError: {},

    searchingPayment: false,
    searchPaymentError: {},
    searchPayment: {},

    offsetting: false,
    offsetSuccess: {},

    allocating: false,
    allocationError: {},
    allocationSuccess: {},

    gettingLedger: false,
    myStudentLedger: {},
    studentLedgerError: {},

    myFeesStructure: [],
    feesStructureError: {},
    gettingFeesStructure: false,

    offsetInvoice: {},
    offsetError: {},
    offsettingInvoice: false,

    generatedInvoice: {},
    generateError: {},
    generatingInvoice: false,

    gettingFinanceClearance: false,
    financeClearanceError: {},
    financeClearance: {},

    downloadFinancialStatement: false,
    financialStatementSuccess: {},
    financialStatementError: {},
  },
};

export default payment;
