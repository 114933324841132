import { Tag } from 'antd';
import { isString } from 'antd/es/button';
import { toUpper } from 'lodash';
import PropTypes, { any, string } from 'prop-types';
import React from 'react';

function NavTag({ title, value, color, ...rest }) {
  return (
    <span size="0" className="info-tags text-sm text-uppercase font600">
      <Tag
        className="text-wrap"
        style={{
          marginRight: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        {isString(title) ? toUpper(title) : title}
      </Tag>
      <Tag
        className="text-wrap"
        color={color}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        {...rest}
      >
        {value}
      </Tag>
    </span>
  );
}

NavTag.propTypes = {
  title: PropTypes.oneOfType([any]).isRequired,
  value: PropTypes.oneOfType([any, string]).isRequired,
  color: PropTypes.string.isRequired,
};
export default NavTag;
