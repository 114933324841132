import { Collapse } from 'antd';
import { isEmpty, isEqual, map, sortBy, sum, sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaPaperclip } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { DataSpinner } from '../../../components/common';
import { paymentActions } from '../../../config/actions';
import Invoice from './Invoice';

function SemesterInvoices() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { invoices, loading } = useSelector((state) => state.myInvoice);
  const [invoiceGrandTotalAmount, setInvoiceGrandTotalAmount] = useState(0);
  const [invoiceGrandAmountPaid, seInvoiceGrandAmountPaid] = useState(0);
  const [invoiceGrandAmountDue, seInvoiceGrandAmountDue] = useState(0);
  const [activeKey, setActiveKey] = useState(['1']);

  const getInvoices = () => {
    dispatch(
      paymentActions.getMyInvoices({
        student_programme_id: currentStudentProgramme?.student_programme_id,
      })
    );
  };

  useEffect(() => {
    if (isEmpty(invoices)) getInvoices();
  }, []);

  useEffect(() => {
    if (!isEmpty(invoices)) {
      let invoiceAmounts = 0;
      let invoiceAmountsDue = 0;
      let invoiceAmountsPaid = 0;

      invoices.forEach((invoice) => {
        const tuitionAmounts = sumBy(
          invoice.tuition_invoices,
          'invoice_amount'
        );
        const tuitionDue = sumBy(invoice.tuition_invoices, 'amount_due');
        const tuitionPaid = sumBy(invoice.tuition_invoices, 'amount_paid');
        const functionalAmounts = sumBy(
          invoice.functional_fees_invoices,
          'invoice_amount'
        );
        const functionalDue = sumBy(
          invoice.functional_fees_invoices,
          'amount_due'
        );
        const functionalPaid = sumBy(
          invoice.functional_fees_invoices,
          'amount_paid'
        );
        const otherAmounts = sumBy(
          invoice.other_fees_invoices,
          'invoice_amount'
        );
        const otherFeesDue = sumBy(invoice.other_fees_invoices, 'amount_due');
        const otherFeesPaid = sumBy(invoice.other_fees_invoices, 'amount_paid');
        const manualAmounts = sumBy(invoice.manual_invoices, 'invoice_amount');
        const manualsDue = sumBy(invoice.manual_invoices, 'amount_due');
        const manualsPaid = sumBy(invoice.manual_invoices, 'amount_paid');

        invoiceAmounts += sum([
          tuitionAmounts,
          functionalAmounts,
          otherAmounts,
          manualAmounts,
        ]);
        invoiceAmountsDue += sum([
          tuitionDue,
          functionalDue,
          otherFeesDue,
          manualsDue,
        ]);
        invoiceAmountsPaid += sum([
          tuitionPaid,
          functionalPaid,
          otherFeesPaid,
          manualsPaid,
        ]);
      });
      setInvoiceGrandTotalAmount(invoiceAmounts);
      seInvoiceGrandAmountDue(invoiceAmountsDue);
      seInvoiceGrandAmountPaid(invoiceAmountsPaid);
    }
  }, [invoices]);

  return (
    <>
      <Row className="text-center text-muted">
        <Col md={3} className="mb-2">
          <Card>
            <Card.Body>
              <Card.Text className="text-xs font600 mb-2">
                TOTAL INVOICE AMOUNT
              </Card.Text>
              <span className="text-info font600 mx-1">
                {`${invoiceGrandTotalAmount.toLocaleString()} UGX`}
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="mb-2">
          <Card>
            <Card.Body>
              <Card.Text className="text-xs font600 mb-2">
                TOTAL INVOICE AMOUNT PAID
              </Card.Text>
              <span className="text-success font600 mx-1">
                {`${invoiceGrandAmountPaid.toLocaleString()} UGX`}
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="mb-2">
          <Card>
            <Card.Body>
              <Card.Text className="text-xs font600 mb-2">
                TOTAL INVOICE AMOUNT DUE
              </Card.Text>
              <span className="text-danger font600 mx-1">
                {`${invoiceGrandAmountDue.toLocaleString()} UGX`}
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="mb-2">
          <Card>
            <Card.Body>
              <Card.Text className="text-xs font600 mb-2">
                PERCENTAGE COMPLETION
              </Card.Text>
              <span
                className={`mx-1 font600 ${
                  (invoiceGrandAmountPaid / invoiceGrandTotalAmount) * 100 > 70
                    ? 'text-success'
                    : 'text-danger'
                }`}
              >
                {`${
                  invoiceGrandTotalAmount !== 0
                    ? (
                        (invoiceGrandAmountPaid / invoiceGrandTotalAmount) *
                        100
                      ).toFixed(2)
                    : 0
                } %`}
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="mt-2">
        {loading && isEmpty(invoices) ? (
          <DataSpinner />
        ) : (
          <Collapse
            accordion
            activeKey={activeKey}
            onChange={setActiveKey}
            defaultActiveKey={['1']}
            expandIconPosition="end"
            items={map(sortBy(invoices, 'academic_year'), (invoice, index) => ({
              key: `${index + 1}`,
              label: (
                <span
                  className={`font600  ${
                    isEqual(activeKey[0], `${index + 1}`) ? 'text-danger' : ''
                  }`}
                >
                  <FaPaperclip className="me-1" />
                  {`${invoice.programme_study_years} - ${invoice.semester} - ${invoice.academic_year}`}
                </span>
              ),
              children: <Invoice data={invoice} />,
            }))}
          />
        )}
      </div>
    </>
  );
}

export default SemesterInvoices;
