import { Alert } from 'antd';
import { find, isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import PropTypes, { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaPrint } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { InputText, ReloadButton } from '../../components/common';
import {
  paymentActions,
  registrationActions,
  settingActions,
} from '../../config/actions';
import usePrevious from '../Hooks/usePrevious';
import CourseUnitsTable from './CourseUnitsTable';

function SingleRegistrationHistory({
  registrationHistory,
  setSelectedHistory,
}) {
  const { invoices } = useSelector((state) => state.myInvoice);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { gettingRegistrationHistory, allExamPermits, checkExamPermit } =
    useSelector((state) => state.registration);
  const { serverError } = useSelector((state) => state.server);
  const [permitStatus, setPermitStatus] = useState({});
  const [error, setError] = useState('');
  const prevState = usePrevious({ serverError });
  // const isMubs = whichInstitution('MUBS', institutionStructure);

  const dispatch = useDispatch();

  const getInvoices = () => {
    dispatch(
      paymentActions.getMyInvoices({
        student_programme_id: currentStudentProgramme?.student_programme_id,
      })
    );
  };

  const fetchPermit = () => {
    dispatch(
      registrationActions.printExaminationPermit(
        registrationHistory?.id || registrationHistory?.registration_id
      )
    );
    setError('');
  };

  useEffect(() => {
    const res = find(
      allExamPermits,
      (permit) =>
        permit.registrationId ===
        (registrationHistory?.id || registrationHistory?.registration_id)
    );
    if (res) {
      setPermitStatus(res);
    } else fetchPermit();
  }, [allExamPermits]);

  useEffect(() => {
    if (isEmpty(invoices)) getInvoices();
  }, []);

  useEffect(() => {
    if (!isEmpty(prevState)) {
      if (!isEmpty(serverError) && prevState?.serverError !== serverError) {
        setError(
          serverError?.error?.message ||
            serverError?.error ||
            serverError?.server?.message
        );
      }
    }
  }, [serverError]);

  const downloadHandler = (cardType) => {
    if (cardType) {
      dispatch(settingActions.setShowModal(true));
      dispatch(settingActions.setRegistrationCardType(cardType));

      let courseUnits = [];

      if (registrationHistory?.course_units) {
        courseUnits = registrationHistory?.course_units;

        setSelectedHistory({
          ...registrationHistory,
          courseUnits,
        });
      }
    }
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <InputText
            label="REGISTRATION TYPE"
            defaultValue={
              registrationHistory?.registrationType?.metadata_value ||
              registrationHistory?.registration_type
            }
            disabled
          />
        </Col>
        <Col md={4}>
          <InputText
            label="REGISTERED BY"
            defaultValue={
              registrationHistory?.registered_by === 'STUDENT'
                ? 'SELF'
                : registrationHistory?.registered_by
            }
            disabled
          />
        </Col>
        <Col md={4}>
          <InputText
            label="DATE OF REG."
            defaultValue={moment(registrationHistory?.created_at).format(
              'ddd, MMM Do YYYY, h:mm:ss a'
            )}
            disabled
          />
        </Col>
      </Row>
      {error && !permitStatus.enabled && (
        <Alert
          showIcon
          type="error"
          className="my-2 font600"
          message={`${error}: As a result, the exam permit cannot be printed. `}
        />
      )}
      <Card className="mt-2">
        <Card.Header className="py-2 text-info font600 text-xs">
          COURSE/MODULES REGISTERED
          {registrationHistory?.is_active &&
            !isEmpty(registrationHistory?.course_units) === true && (
              <div className="card-options">
                <Button
                  size="sm"
                  variant="info"
                  className="text-sm fw-bold me-2"
                  disabled={gettingRegistrationHistory}
                  onClick={() => downloadHandler('proofOfRegistration')}
                >
                  <FaPrint className="me-2" />
                  PRINT PROOF OF REGISTRATION
                </Button>

                {permitStatus.enabled ? (
                  <Button
                    size="sm"
                    variant="info"
                    className="text-sm fw-bold"
                    disabled={gettingRegistrationHistory}
                    onClick={() => downloadHandler('examPermit')}
                  >
                    <FaPrint className="me-2" />
                    PRINT EXAM PERMIT
                  </Button>
                ) : (
                  <ReloadButton
                    loading={checkExamPermit}
                    loadingText="LOADING..."
                    onClick={fetchPermit}
                  />
                )}
              </div>
            )}
        </Card.Header>
        <Card.Body className="p-0">
          <CourseUnitsTable
            courseUnits={orderBy(registrationHistory?.course_units, [
              'course_unit_code',
            ])}
            keyField="course_unit_id"
          />
        </Card.Body>
      </Card>
    </>
  );
}

SingleRegistrationHistory.defaultProps = {
  registrationHistory: {},
};

SingleRegistrationHistory.propTypes = {
  registrationHistory: PropTypes.oneOfType([object]),
  setSelectedHistory: PropTypes.func.isRequired,
};

export default SingleRegistrationHistory;
