import { PayCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { filter, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReloadButton } from '../../../components/common';
import { paymentActions, settingActions } from '../../../config/actions';
import MenuList from '../../../config/routes/MenuList';
import GraduationInvoices from './GraduationInvoices';
import SemesterInvoices from './SemesterInvoices';

function MyInvoices() {
  const dispatch = useDispatch();
  const { appNavigation, selectedMenu } = useSelector((state) => state.setting);
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { loading, invoices } = useSelector((state) => state.myInvoice);
  const [currentMenu, setCurrentMenu] = useState('semester-invoices');

  const navigateUser = (route) => {
    const { MyInvoice } = MenuList;
    dispatch(
      settingActions.setNavigation({
        ...appNavigation,
        menuLevel_1: 'payments',
        menuLevel_2: MyInvoice?.label,
        menuLevel_3: route,
      })
    );
  };

  useEffect(() => {
    const { key } = selectedMenu;
    const activeInvoiceTab = currentMenu.split('-').join(' ');
    if (key === 'my-invoices') {
      navigateUser(activeInvoiceTab);
    }
  }, []);

  const getInvoices = () => {
    dispatch(
      paymentActions.getMyInvoices({
        student_programme_id: currentStudentProgramme?.student_programme_id,
      })
    );
  };

  return (
    <Card
      loading={loading && isEmpty(invoices)}
      tabList={filter(
        [
          {
            key: 'semester-invoices',
            tab: 'SEMESTER INVOICES/BILLS',
            icon: <PayCircleOutlined />,
          },
          {
            key: 'graduation-invoices',
            tab: 'GRADUATION INVOICES',
            hidden: currentStudentProgramme?.on_provisional_list !== true,
          },
        ],
        (item) => !item.hidden
      )}
      activeTabKey={currentMenu}
      onTabChange={setCurrentMenu}
      tabProps={{
        type: 'card',
        defaultActiveKey: 'semester-invoices',
        rootClassName: 'font600',
        tabBarStyle: {
          marginTop: 15,
        },
      }}
      tabBarExtraContent={
        <ReloadButton loading={loading} onClick={getInvoices} />
      }
    >
      <div>
        {currentMenu === 'semester-invoices' && <SemesterInvoices />}
        {currentMenu === 'graduation-invoices' &&
          currentStudentProgramme?.on_provisional_list === true && (
            <GraduationInvoices />
          )}
      </div>
    </Card>
  );
}

export default MyInvoices;
