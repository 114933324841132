import { ClockCircleOutlined } from '@ant-design/icons';
import { MinusCircleOutlined, SyncOutlined } from '@ant-design/icons/lib/icons';
import { Card, Flex, Tag } from 'antd';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  DataNotFound,
  DataSpinner,
  ReloadButton,
} from '../../components/common';
import { calendarActions } from '../../config/actions';

function AcademicCalendar() {
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { currentSemester } = useSelector((state) => state.app);
  const { gettingCalendarEvents, calendarYearEvents } = useSelector(
    (state) => state.calendar
  );
  const dispatch = useDispatch();

  const fetchAcademicCalendar = () =>
    dispatch(calendarActions.getAcademicYearEvents(currentStudentProgramme.id));

  useEffect(() => {
    if (currentStudentProgramme && isEmpty(calendarYearEvents)) {
      fetchAcademicCalendar();
    }
  }, []);

  const setTagState = (status) => {
    let tagState = {};
    if (status === 'Open')
      tagState = {
        color: 'green',
        icon: <SyncOutlined spin />,
      };
    else if (status === 'Close')
      tagState = {
        color: 'volcano',
        icon: <MinusCircleOutlined />,
      };
    else
      tagState = {
        color: 'default',
        icon: <ClockCircleOutlined />,
      };

    return tagState;
  };

  const eventStatus = useCallback(
    (row) => (
      <Tag
        icon={setTagState(row?.event_status)?.icon}
        color={setTagState(row?.event_status)?.color}
        className="d-flex align-items-center py-2 "
      >
        {row?.event_status}
      </Tag>
    ),

    []
  );

  return (
    <Card
      loading={gettingCalendarEvents && isEmpty(calendarYearEvents)}
      title={<span>ACADEMIC CALENDAR</span>}
      extra={
        <ReloadButton
          loading={gettingCalendarEvents}
          onClick={() => fetchAcademicCalendar()}
        />
      }
    >
      <div>
        {gettingCalendarEvents && isEmpty(calendarYearEvents) ? (
          <DataSpinner />
        ) : (
          <>
            {isEmpty(calendarYearEvents) ? (
              <DataNotFound message="There is currently no running Academic Calendar" />
            ) : (
              <>
                <div className="bg-white p-1 fs-3 text-center">
                  ACADEMIC YEAR - {calendarYearEvents[0]?.academic_year}
                </div>
                {orderBy(calendarYearEvents, ['semester'], 'asc').map(
                  (semester) => (
                    <Fragment key={semester?.semester || 'other_event'}>
                      <Flex
                        align="center"
                        justify="space-between"
                        className="bg-dark mt-3 py-2 px-1"
                      >
                        <span className="fs-4 font600 text-white">
                          {semester?.semester || 'OTHER EVENTS'}
                        </span>
                        <span>
                          {semester.semester === currentSemester?.semester && (
                            <Tag color="success">
                              <strong>Current</strong>
                            </Tag>
                          )}
                        </span>
                      </Flex>

                      <div className="p-0 border">
                        <DataTable
                          data={semester.events || []}
                          noHeader
                          columns={[
                            {
                              name: 'EVENT',
                              selector: (row) => row.event_name || '',
                            },
                            {
                              name: 'DESCRIPTION',
                              selector: (row) => row.description || '',
                            },
                            {
                              name: 'START DATE',
                              selector: (row) =>
                                moment(row.start_date).format('Do MMM YYYY') ||
                                '',
                            },
                            {
                              name: 'END DATE',
                              selector: (row) =>
                                moment(row.end_date).format('Do MMM YYYY') ||
                                '',
                            },
                            {
                              name: 'STATUS',
                              cell: eventStatus,
                            },
                          ]}
                          noDataComponent={
                            <DataNotFound message="NO EVENTS FOR THIS SEMESTER" />
                          }
                          dense
                          className="text-sm"
                          customStyles={{
                            headCells: {
                              style: {
                                fontWeight: 'bold',
                              },
                            },
                          }}
                        />
                      </div>
                    </Fragment>
                  )
                )}
              </>
            )}
          </>
        )}
      </div>
    </Card>
  );
}

export default AcademicCalendar;
