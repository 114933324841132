import { Card } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { DataNotFound, ReloadButton } from '../../../components/common';
import { serviceActions } from '../../../config/actions';
import StudentCardDetails from './StudentCardDetails';
import UploadedCardImage from './UploadedCardImage';

function IdentificationCard() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { studentIdTracker, gettingStudentIdTracker } = useSelector(
    (state) => state.service
  );

  const getIdTrackerDetails = () =>
    dispatch(serviceActions.getStudentIdTracker(currentStudentProgramme?.id));

  useEffect(() => {
    if (isEmpty(studentIdTracker)) getIdTrackerDetails();
  }, []);

  return (
    <Card
      loading={gettingStudentIdTracker && isEmpty(studentIdTracker)}
      title={
        <span className="font600 text-primary">MY IDENTIFICATION CARD</span>
      }
      extra={
        <ReloadButton
          loading={gettingStudentIdTracker}
          onClick={() => getIdTrackerDetails()}
        />
      }
    >
      <div>
        {isEmpty(studentIdTracker) ? (
          <DataNotFound message="NO ID CARD RECORD FOUND" />
        ) : (
          <Row>
            <Col md={5}>
              <StudentCardDetails />
            </Col>
            <Col md={7}>
              <UploadedCardImage />
            </Col>
          </Row>
        )}
      </div>
    </Card>
  );
}

export default IdentificationCard;
