import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Card, Flex } from 'antd';
import { DataNotFound, ReloadButton } from '../../components/common';
import { paymentActions } from '../../config/actions';
import usePrevious from '../Hooks/usePrevious';
import FinanceClearanceDetails from './FinanceClearanceDetails';
import StudentAcademicDetails from './StudentAcademicDetails';

function FinanceClearance() {
  const dispatch = useDispatch();
  const { currentStudentProgramme } = useSelector((state) => state.auth);
  const { financeClearance, financeClearanceError, gettingFinanceClearance } =
    useSelector((state) => state.myTransaction);
  const [errorMessage, setErrorMessage] = useState(null);
  const prevState = usePrevious({ financeClearance, financeClearanceError });

  const getFinanceClearance = () =>
    dispatch(
      paymentActions.getStudentFinanceClearance(currentStudentProgramme?.id)
    );

  useEffect(() => {
    if (isEmpty(financeClearance)) getFinanceClearance();
  }, [financeClearance]);

  useEffect(() => {
    if (
      prevState &&
      !isEmpty(financeClearanceError) &&
      prevState.financeClearanceError !== financeClearanceError
    ) {
      setErrorMessage(financeClearanceError?.server?.message);
    } else {
      setErrorMessage(null);
    }
  }, [financeClearanceError]);

  return (
    <>
      <Card
        loading={gettingFinanceClearance && isEmpty(financeClearance)}
        title={
          <Flex
            justify="space-between"
            align="center"
            className="text-primary fw-bold"
          >
            <div>MY FINANCE CLEARANCE</div>
            <div className="card-options">
              <ReloadButton
                loading={gettingFinanceClearance}
                onClick={() => getFinanceClearance()}
              />
            </div>
          </Flex>
        }
        className="border"
      >
        <div>
          {errorMessage && (
            <Alert
              type="error"
              className="font600 text-uppercase text-sm mb-3"
              showIcon
              description={<span className="mx-1">{errorMessage}</span>}
            />
          )}

          {isEmpty(financeClearance) ? (
            <DataNotFound message="NO FINANCE CLEARANCE DATA FOUND" />
          ) : (
            <>
              <Card className="border-0">
                <div>
                  <StudentAcademicDetails />
                </div>
                <div>
                  <FinanceClearanceDetails />
                </div>
              </Card>
            </>
          )}
        </div>
      </Card>
    </>
  );
}

export default FinanceClearance;
