import { last } from 'lodash';
import moment from 'moment';
import PropTypes, { any } from 'prop-types';
import React from 'react';

function TestimonialFooter({ studentStatus, result }) {
  return (
    <div className="mt-2">
      {studentStatus ? (
        <div className="small-text-header bg-white rounded-lg mt-2 mb-4">
          <div>
            <div className="d-inline fw-bold">
              Minimum Graduation Load(CUs):
            </div>
            <div className="d-inline"> </div>
          </div>
          <div>
            {`Total Credit Units: ${last(result.semesters)?.cumulative_tcu}`}
          </div>
          <div className="mb-4">
            <div className="d-inline fw-bold">Award:</div>
            <div className="d-inline">{result?.programme_title}</div>
          </div>
          <div className="mb-4">
            <div className="d-inline fw-bold">Class of Award:</div>
            <div className="d-inline">Second Class Honours-Upper Division</div>
          </div>
        </div>
      ) : (
        <div className="small-text-header bg-white rounded-lg my-4">
          <div className=" fw-bold d-inline me-2">
            Minimum Graduation Load(CUs):
          </div>
          <span>{result?.graduation_load}</span>

          <div>
            <div className="fw-bold d-inline me-2"> Total Credit Units:</div>
            <span>{last(result.semesters)?.cumulative_tcu}</span>
          </div>
        </div>
      )}
      <br />
      <br />
      <div className="clearfix mt-5">
        ...............................................................................................................................
      </div>
      <div className="clearfix mt-2 mb-5">Dean&apos;s Signature</div>
      <div className="mt-5">
        <div
          className="clearfix text-danger mt-5 mb-3"
          style={{ marginTop: '10Rem !important' }}
        >
          <span className="fw-bold me-2">NOTE:</span> THE RESULTS ON THIS
          DOCUMENT ARE PROVISIONAL, AND MUST BEAR A STAMP FROM THE REGISTRAR. A
          FULL TRANSCRIPT IS ISSUED BY THE ACADEMIC REGISTRAR.
          <p> THE MEDIUM OF INSTRUCTION IS ENGLISH.</p>
        </div>
        <div className="mt-2 fw-bold w-100">NORMAL PROGRESS: NP</div>
        <div className="mt-2 fw-bold w-100">RETAKE PAPERS: RT</div>
        <div className="mt-2 fw-bold w-100">CONCEDED PASS: CP</div>
        <div className="mt-2 fw-bold w-100">AUDITED COURSE: (AU)</div>
        <div className="mt-4">
          {` This is a Testimonial as at  ${moment(new Date()).format(
            'll'
          )} and is Valid with a stamp and
          signature. Any alterations of this document makes it invalid`}
        </div>
      </div>
    </div>
  );
}

TestimonialFooter.defaultProps = {
  studentStatus: false,
};

TestimonialFooter.propTypes = {
  studentStatus: PropTypes.bool,
  result: PropTypes.oneOfType([any]).isRequired,
};
export default TestimonialFooter;
