import { BookOutlined, PayCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentReferenceActions, settingActions } from '../../config/actions';
import MenuList from '../../config/routes/MenuList';
import GeneratePaymentReference from './GeneratePaymentReference';
import PaymentReferencesDetails from './PaymentReferencesDetails';
import ReferenceModal from './ReferenceModal';

function PaymentReferences() {
  const dispatch = useDispatch();
  const { paymentReferences } = useSelector((state) => state.paymentReference);
  const { appNavigation } = useSelector((state) => state.setting);
  const [activeKey, setActiveKey] = useState(1);
  const [activeTab, setActiveTab] = useState('generate-reference');
  const [referenceTab, setReferenceTab] = useState('active');

  const reloadPaymentReferences = () => {
    dispatch(paymentReferenceActions.getPaymentReferences());
  };

  useEffect(() => {
    if (isEmpty(paymentReferences)) reloadPaymentReferences();
  }, []);

  const navigateUser = (route, route2 = null) => {
    const {
      PaymentReference: { label },
    } = MenuList;

    if (!isEmpty(label)) {
      dispatch(
        settingActions.setNavigation({
          ...appNavigation,
          menuLevel_1: label,
          menuLevel_2: route,
          menuLevel_3: route2,
        })
      );
    }
  };

  return (
    <>
      <Card
        activeTabKey={activeTab}
        onTabChange={setActiveTab}
        tabList={[
          {
            key: 'generate-reference',
            tab: 'GENERATE NEW PRN',
            icon: <PayCircleOutlined />,
          },
          {
            key: 'references',
            tab: 'MY PAYMENT REFs',
            icon: <BookOutlined />,
          },
        ]}
        tabProps={{
          type: 'card',
          defaultActiveKey: 'generate-reference',
          rootClassName: 'font600',
          tabBarStyle: {
            marginTop: '15px',
          },
        }}
      >
        {activeTab === 'generate-reference' && (
          <GeneratePaymentReference
            activeTab={activeTab}
            navigateUser={navigateUser}
          />
        )}
        {activeTab === 'references' && (
          <div className="border-0">
            <PaymentReferencesDetails
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              referenceTab={referenceTab}
              setReferenceTab={setReferenceTab}
              navigateUser={navigateUser}
            />
          </div>
        )}
      </Card>
      <ReferenceModal switchTab={() => setActiveTab('references')} />
    </>
  );
}

export default PaymentReferences;
