import { Card } from 'antd';
import { find, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaReply } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlertMessage,
  DataSpinner,
  ReloadButton,
} from '../../../components/common';
import { serviceActions } from '../../../config/actions';
import ApplicationForm from './ApplicationForm';
import ApplicationStatus from './ApplicationStatus';

function ChangeOfProgramme({ setCurrentTab, currentTab, navigateUser }) {
  const dispatch = useDispatch();
  const { activeProgramme } = useSelector((state) => state.auth);
  const {
    pendingApplications,
    fetchingPendingApplications,
    gettingProgrammes,
    programmes,
    serviceEvents,
  } = useSelector((state) => state.service);
  const [currentEvent, setCurrentEvent] = useState({});
  const [pendingChangeOfProgramme, setPendingChangeOfProgramme] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const getPendingApplications = () => {
    dispatch(
      serviceActions.getPendingApplications(
        'change-of-programme',
        activeProgramme?.id,
        currentTab
      )
    );
  };

  const getServiceEvent = () => {
    dispatch(serviceActions.getServiceEvents(activeProgramme.id, currentTab));
  };

  const getCurrentEvent = () =>
    find(
      serviceEvents,
      (e) =>
        parseInt(e.studentProgrammeId, 10) ===
          parseInt(activeProgramme.id, 10) && e.category === currentTab
    );

  const getProgrammes = () => {
    dispatch(serviceActions.getProgrammes());
  };

  useEffect(() => {
    getPendingApplications();

    if (
      !isEmpty(activeProgramme) &&
      isArray(serviceEvents) &&
      !isEmpty(activeProgramme)
    ) {
      const getEvent = getCurrentEvent();

      if (!getEvent) getServiceEvent();
    }

    if (isEmpty(programmes)) getProgrammes();
  }, []);

  useEffect(() => {
    if (pendingApplications) {
      const findService = pendingApplications.find(
        (app) =>
          app.category === currentTab &&
          app.studentProgrammeId === activeProgramme?.id
      );
      setPendingChangeOfProgramme(findService?.data || null);
    }

    if (!isEmpty(activeProgramme) && isArray(serviceEvents)) {
      const getEvent = getCurrentEvent();
      setCurrentEvent(getEvent?.event || {});
    }
  }, [pendingApplications, serviceEvents]);

  const handleClick = () => {
    setCurrentTab('service-dashboard');
    navigateUser();
  };

  return (
    <Card
      loading={fetchingPendingApplications || gettingProgrammes}
      title={
        <div className="text-uppercase font600 text-primary">
          {isEmpty(pendingChangeOfProgramme)
            ? 'APPLY FOR CHANGE OF PROGRAMME'
            : 'PENDING CHANGE OF PROGRAMME APPLICATION'}
        </div>
      }
      extra={
        <>
          {isEmpty(pendingChangeOfProgramme) && (
            <ReloadButton
              onClick={getProgrammes}
              loading={gettingProgrammes}
              text="Reload Programmes"
              loadingText="Loading Programmes..."
            />
          )}
          <Button
            size="sm"
            onClick={handleClick}
            className="text-sm font600 text-uppercase ms-1"
            variant="warning"
          >
            <FaReply className="me-1" />
            Back
          </Button>
        </>
      }
    >
      <div>
        {fetchingPendingApplications ? (
          <DataSpinner text="Please wait..." />
        ) : (
          <>
            {isEmpty(activeProgramme) ? (
              <AlertMessage message="You currently have no active programme" />
            ) : (
              <>
                {isEmpty(pendingChangeOfProgramme) ? (
                  <ApplicationForm
                    currentEvent={currentEvent}
                    setCurrentTab={setCurrentTab}
                    currentTab={currentTab}
                    navigateUser={navigateUser}
                  />
                ) : (
                  <>
                    {isEditing ? (
                      <ApplicationForm
                        dataToEdit={pendingChangeOfProgramme}
                        currentEvent={currentEvent}
                        setCurrentTab={setCurrentTab}
                        editing
                        currentTab={currentTab}
                        navigateUser={navigateUser}
                      />
                    ) : (
                      <ApplicationStatus
                        application={pendingChangeOfProgramme}
                        setIsEditing={setIsEditing}
                        currentTab={currentTab}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Card>
  );
}

ChangeOfProgramme.propTypes = {
  setCurrentTab: PropTypes.func.isRequired,
  currentTab: PropTypes.string.isRequired,
  navigateUser: PropTypes.func.isRequired,
};

export default ChangeOfProgramme;
