import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { settingActions } from '../../config/actions';
import SideTitle from './SideTitle';
import SideBar from './SideBar';

function ToggleMenu() {
  const dispatch = useDispatch();
  const { isToggled } = useSelector((state) => state.setting);
  const isMobileDevice = useMediaQuery({ maxWidth: 767 });

  const handleClick = () => dispatch(settingActions.setIsToggled(!isToggled));

  return (
    <>
      <Popover
        showArrow
        placement="bottomLeft"
        trigger="click"
        open={isMobileDevice && isToggled}
        title={<SideTitle />}
        content={<SideBar />}
        onOpenChange={handleClick}
        className="ms-2"
      >
        <Button
          icon={isToggled ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={handleClick}
          size="small"
        >
          MENU
        </Button>
      </Popover>
    </>
  );
}

export default ToggleMenu;
