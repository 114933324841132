import { Button, Card } from 'antd';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { FaReply } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AlertMessage, DataSpinner } from '../../../components/common';
import {
  enrollmentActions,
  registrationActions,
  serviceActions,
} from '../../../config/actions';
import RegisterStudent from '../../RegistrationHistory/RegisterStudent';
import PendingApplication from './PendingApplication';

function AdministrativeRegistration({ setCurrentTab, navigateUser }) {
  const { activeProgramme } = useSelector((state) => state.auth);
  const { currentEvents } = useSelector((state) => state.enrollment);
  const { pendingRegistrationApplications, fetchingPendingApplications } =
    useSelector((state) => state.service);
  const dispatch = useDispatch();
  const [semesterEvent, setSemesterEvent] = useState({});
  const [applyMode, setApplyMode] = useState(false);

  const getPendingRequests = () => {
    dispatch(serviceActions.getPendingRegistrationApplications());
  };

  useEffect(() => {
    getPendingRequests();
  }, []);

  useEffect(() => {
    setApplyMode(false);
  }, [fetchingPendingApplications]);

  useEffect(() => {
    if (!isEmpty(currentEvents)) {
      const findEvent = currentEvents.find(
        (event) =>
          parseInt(event.programme_id, 10) === parseInt(activeProgramme.id, 10)
      );
      setSemesterEvent(findEvent ? findEvent.event : {});
    }
  }, [currentEvents, activeProgramme]);

  const handleClick = () => {
    setCurrentTab('service-dashboard');
    navigateUser();
  };

  const reloadEnrollmentEvent = () => {
    if (!isEmpty(activeProgramme)) {
      dispatch(enrollmentActions.getCurrentEvents(activeProgramme.id));

      if (
        isEmpty(currentEvents.studentRegistration) &&
        !isEmpty(currentEvents.registrationEvent)
      ) {
        dispatch(registrationActions.getCourseUnits(activeProgramme.id));
      }
    } else toast.error('YOU CURRENTLY HAVE NO ACTIVE PROGRAMME');
  };

  return (
    <>
      <Card
        title={
          <div className="text-uppercase font600 text-primary">
            {isEmpty(pendingRegistrationApplications)
              ? 'APPLY FOR ADMINISTRATIVE REGISTRATION'
              : 'PENDING ADMINISTRATIVE REGISTRATION APPLICATION'}
          </div>
        }
        extra={
          <div>
            {isEmpty(pendingRegistrationApplications) && (
              <Button
                size="small"
                onClick={() => {
                  setApplyMode(!applyMode);
                }}
                danger={applyMode}
                className="text-sm font600 text-uppercase me-2"
                type={`${applyMode ? 'default' : 'primary'}`}
              >
                {applyMode ? 'CANCEL' : 'NEW APPLICATION'}
              </Button>
            )}
            <Button
              size="small"
              onClick={handleClick}
              className="text-sm font600 text-uppercase ms-1"
              icon={<FaReply />}
            >
              Back
            </Button>
          </div>
        }
      >
        <div>
          {fetchingPendingApplications ? (
            <DataSpinner text="Please wait..." />
          ) : (
            <>
              {isEmpty(activeProgramme) ? (
                <AlertMessage message="You currently have no active programme" />
              ) : (
                <>
                  {isEmpty(pendingRegistrationApplications) || applyMode ? (
                    <RegisterStudent
                      registrationEvent={semesterEvent.registrationEvent}
                      enrollmentEvent={semesterEvent.enrollmentEvent}
                      enrollmentHistory={semesterEvent.studentEnrollment}
                      reloadEnrollmentEvent={reloadEnrollmentEvent}
                      isAdministrative
                    />
                  ) : (
                    <>
                      <PendingApplication
                        applicationRequests={pendingRegistrationApplications}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Card>
    </>
  );
}

AdministrativeRegistration.propTypes = {
  setCurrentTab: PropTypes.func.isRequired,
  navigateUser: PropTypes.string.isRequired,
};

export default AdministrativeRegistration;
